import { makeAutoObservable, runInAction } from "mobx";
import { Store, CreateUserData, IPriceStore, PriceData, allPriceData } from "../global/types";
import { createBootleg, getAllBootlegData, getOneUserData, updateBootlegData } from "../api/user.action";
import { addPrice, getAllPriceData, getOnePriceData, updatePriceData } from "../api/price.action";

class PriceStore implements IPriceStore {
    rootStore: Store;
    allPriceData: allPriceData[] = [];
    priceData: PriceData | null = null;
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    totalItems: number = 0;
    totalPages: number = 0;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllPriceData = async (
        action: 'change_page' | 'search' | 'change_limit' | '',
        page: number,
        searchTerm: string,
        limit: number
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_limit':
                        this.limit = limit
                        this.page = 1
                        break;
                    default:
                        break;
                }
            })
            const data: any = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm?.trim()
            }
            const response = await getAllPriceData(data);
            runInAction(() => {
                // this.allPriceData = response.data?.data?.prices || [];
                this.allPriceData = (response.data?.data?.prices || []).map((price: any) => {
                    return {
                        ...price,  // Spread the existing price data
                        price: `$${price.price.toFixed(2)}`, 
                    };
                })
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchOnePriceData = async (id: string) => {
        this.setLoading(true);
        try {
            const response = await getOnePriceData(id);
            runInAction(() => {
                const priceData = response.data.data;
                this.priceData = {
                    ...priceData,
                    price: priceData.price.toFixed(2)
                };
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    updatePriceDetails = async (data: PriceData) => {
        this.setLoading(true);
        try {
            const requestBody: any = {
                price: data.price,
                ios_in_app_purchase: data.ios_in_app_purchase,
                android_in_app_purchase: data.android_in_app_purchase,
                price_id: this.priceData?._id
            }

            const response = await updatePriceData(requestBody);
            runInAction(() => {
                this.priceData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    addNewPrice = async (data: PriceData) => {
        this.setLoading(true);
        try {
            const requestBody: any = {
                price: data.price,
                ios_in_app_purchase: data?.ios_in_app_purchase,
                android_in_app_purchase: data?.android_in_app_purchase
            }

            const response = await addPrice(requestBody);
            runInAction(() => {
                this.priceData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    resetPricePageDataStates = () => {
        runInAction(() => {
            this.allPriceData = [];
            this.priceData = null;
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.totalItems = 0;
            this.totalPages = 0;
        })
    }
}

export default PriceStore;
