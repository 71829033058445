import { ChartArea, LucideUsers2, User, HandCoins, Music, Coins, MicVocal, MapPinned, Camera } from "lucide-react";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { RootContext } from "../../context";

interface SidebarItems {
  title: string
  icon: React.ReactNode
  link: string,
  adminOnly?: boolean
}

interface SidebarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (value: boolean) => void;
}

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }: SidebarProps) => {
  const location = useLocation();
  const sidebarItems: SidebarItems[] = [
    {
      title: 'Dashboard',
      icon: <ChartArea />,
      link: '/dashboard'
    },
    {
      title: 'Concert',
      icon: <Music />,
      link: '/concerts'
    },
    {
      title: 'Venues',
      icon: <MapPinned />,
      link: '/venues'
    },
    {
      title: 'Transactions',
      icon: <HandCoins />,
      link: '/transactions',
      adminOnly: true
    },
    {
      title: 'Price Management',
      icon: <Coins />,
      link: '/prices',
      adminOnly: true
    },
    {
      title: 'Bootleggers',
      icon: <User />,
      link: '/bootlegs',
      adminOnly: true
    },
    {
      title: 'Users',
      icon: <LucideUsers2 />,
      link: '/customers',
      adminOnly: true
    },
    {
      title: 'Artists',
      icon: <MicVocal />,
      link: '/artists'
    },
    {
      title: 'Photographers',
      icon: <Camera />,
      link: '/photographers'
    }
  ];

  const handleCloseSidebar = () => {
    if(isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <aside className={`bg-white w-full max-w-xs z-40 absolute inset-y-0 left-0 transform ${isSidebarOpen ? '-translate-x-0 h-screen' : '-translate-x-full h-[calc(100vh-48px)] '
      } transition duration-300 ease-in-out md:relative md:translate-x-0 md:border-r-2 border-gray-300 pt-4 md:block md:w-auto p-2 min-w-[250px]`}>
      {sidebarItems.map((item, index) => (
        <SidebarItem
          key={index}
          title={item.title}
          icon={item.icon}
          link={item.link}
          adminOnly={item.adminOnly}
          handleCloseSidebar={handleCloseSidebar}
          isActive={location.pathname.startsWith(item.link) || (item.link === '/dashboard' && location.pathname === '/')}
        />
      ))}
    </aside>
  );
};

const SidebarItem = ({ title, icon, link, isActive, adminOnly, handleCloseSidebar }: {
  title: string,
  icon: React.ReactNode,
  link: string,
  isActive: boolean,
  adminOnly: boolean | undefined
  handleCloseSidebar: () => void
}) => {

  const { authStore: { isSuperAdmin } } = useContext(RootContext);

  if (adminOnly && !isSuperAdmin()) {
    return null
  }

  return (
    <div className="text-sm mb-2" onClick={handleCloseSidebar}>
      <Link
        to={link}
        className={`flex items-center py-2 px-4 rounded-md ${isActive ? 'text-blue-700 font-semibold bg-blue-100' : 'text-gray-600'} hover:text-blue-500 transition-colors`}
      >
        {icon}
        <span className="ml-4">{title}</span>
      </Link>
    </div>
  );
};

export default Sidebar;
